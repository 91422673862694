import React from 'react';
import classNames from 'classnames';

import Ad from 'components/Ad';
import EmbedWidget from 'components/EmbedWidget';
import BlockQuote from 'components/BlockQuote';
import FeatureLink from 'components/FeatureLink';
import { ImageLiftout } from 'components/ImageLiftout';
import { InlineImage } from 'components/InlineImage';
import InlineVideo from 'components/InlineVideo';
import LiftOut from 'components/LiftOut';
import Link from 'components/Link';
import { NewsletterSignupInline } from 'components/NewsletterSignup/Inline';
import NewsvinePoll from 'components/NewsvinePoll';
import { LazyListicleProduct } from 'components/ListicleProduct';
import PullQuote from 'components/PullQuote';
import { ContentLinkCard } from 'components/ContentLinkCard';
import SummaryBox from 'components/SummaryBox';
import { FAQEmbed } from 'components/FAQ';
import { TaboolaRecoReel } from 'components/TaboolaRecoReel';
import { ButtonShowMore } from 'components/ButtonShowMore';
import { RunExperiment } from 'components/RunExperiment';
import { RUN_EXPERIMENT } from 'lib/brandFeatures';
import ShoppingCart from 'components/ShoppingCart';
import { QuickTake } from 'components/QuickTake';
import { CuratedList } from 'components/CuratedList';
import { Statement } from 'components/Statement';
import { Subhed } from 'components/ChatCard/ChatCardFactCheck/Subhed';
import ContentModule from 'components/ContentModule';

import {
  getDebugComponent,
  getRecommendationComponent,
} from './getComponent';

import { TYPE as TABOOLA_RECO_REEL_TYPE } from './getSections/insertTaboolaRecoReel';

const {
  getBodyItemComponent: {
    NewsletterSignupInline: runExperimentNewsletterSignupInlineCampaignNumber,
  },
} = RUN_EXPERIMENT;

function htmlElement(item, i, path) {
  const {
    element, html = [], insertEndmark, ecommerceEnabled, elementId,
  } = item;
  // `Type` must be capitalized for JSX to recognize it as a dynamic tag name.
  const Type = element ?? 'P';

  const className = classNames({
    endmark: insertEndmark,
    'break-above': Type === 'ul',
    'body-ul': Type === 'ul',
    'body-ol': Type === 'ol',
    'body-list-el': Type === 'ul' || Type === 'ol',
  });

  if (!html || !html.length) {
    return <Type key={i} className={className} />;
  }

  if (Type === 'blockquote') {
    const text = html.replace(/(<([^>]+)>)/ig, '');

    if (/class=['"]pullquote["']/.test(html)) {
      return <PullQuote key={i} text={text} />;
    }

    return <BlockQuote key={i} text={text} />;
  }

  if (
    ['h2', 'h3', 'h4'].includes(Type.toLowerCase())
    && !html.includes('<a')
    && ecommerceEnabled
  ) {
    const cleanTitle = html.replace(/[^A-Za-z0-9]/g, '');
    const anchorId = `anchor-${elementId || cleanTitle}`;
    return (
      <Link key={i} href={`${path}#${anchorId}`} id={anchorId} className="scrollLink">
        <Type className={className} dangerouslySetInnerHTML={{ __html: html }} />
      </Link>
    );
  }

  return (
    <Type
      key={i}
      id={elementId ? `anchor-${elementId}` : undefined}
      className={className}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}

function getImageComponent(item, i, lazyLoadSection) {
  const { presentation, elementId } = item;
  // lazyload images by default
  // images should eagerly load if they've been flagged as being in the first section
  // and they are within the first few segments
  const isLazyLoaded = lazyLoadSection || i > 4;
  if (presentation.size === 'fullwidth') {
    return (
      <ImageLiftout
        content={item}
        key={i}
        lazyloaded={isLazyLoaded}
        elementId={elementId}
      />
    );
  }
  return (
    <InlineImage
      content={item}
      key={i}
      presentation={presentation.size}
      lazyloaded={isLazyLoaded}
      elementId={elementId}
    />
  );
}
function getWidgetComponent(
  item,
  i,
  setShowHiddenMarkupAndEmbeds,
  isArticleEcommerceEnabled,
  vertical = '',
) {
  if (!item?.widget) {
    return null;
  }

  const {
    presentation: {
      size,
    },
    widget,
    widget: {
      name,
      properties = {},
    },
  } = item;

  switch (name) {
    case 'nbc_blockquote': {
      const {
        'nbcblockquote-text': text,
        'nbcblockquote-attributionlink': source,
        'nbcblockquote-attribution': attribution,
      } = properties;
      return (
        <BlockQuote attribution={attribution} key={i} source={source} text={text} />
      );
    }

    case 'nbc_liftout': {
      const {
        'liftout-text': text,
      } = properties;
      return (
        <LiftOut key={i} size={size} text={text} />
      );
    }

    case 'nbc_pullquote': // intentional fall-through
    case 'nbc_pull_quote': {
      const {
        'pullquote-attribution': attribution,
        'pullquote-text': text,
        quip,
      } = properties;
      return (
        <PullQuote attribution={attribution} key={i} quip={quip} text={text} size={size} />
      );
    }

    case 'nbc_featuredlink':
      return <FeatureLink key={i} widget={widget} />;

    case 'IFRAMELY_EXTERNAL_EMBED': {
      const url = widget?.properties['canonical-url'] || '';
      const isNbcDomain = /www\.(nbcnews|today|msnbc)\.com/.test(url);
      const hasHeadline = !!widget?.properties?.headline;
      if (isNbcDomain && hasHeadline && isArticleEcommerceEnabled) {
        const transformedWidget = {
          properties: {
            'featuredlink-headline': widget.properties.headline,
            'featuredlink-linktext': widget.properties.description,
            'featuredlink-image': widget.properties['image-url'],
            'featuredlink-linkurl': widget.properties['canonical-url'],
          },
        };
        return <FeatureLink key={i} widget={transformedWidget} />;
      }
      return <EmbedWidget {...item} key={i} />;
    }

    case 'newsvine_large_poll':
      return <NewsvinePoll key={i} widget={widget} />;

    case 'CUSTOM_EMBED': {
      switch (properties.embed.type) {
        case 'BLOCKQUOTE': {
          const {
            attribution,
            attributionUrl: source,
            text,
          } = properties.embed;
          return (
            <BlockQuote attribution={attribution} key={i} source={source} text={text} />
          );
        }

        case 'FACT_CHECK_STATEMENT': {
          const {
            attribution,
            attributionUrl,
            text,
          } = properties.embed;
          return (
            <>
              <Subhed>Statement</Subhed>
              <Statement
                attribution={attribution}
                attributionUrl={attributionUrl}
                key={i}
                text={text}
              />
            </>
          );
        }

        case 'LIFT_OUT': {
          const { text } = properties.embed;
          return (
            <LiftOut key={i} size={size} text={text} />
          );
        }

        case 'PULL_QUOTE': {
          const { attribution, quip, text } = properties.embed;
          return (
            <PullQuote attribution={attribution} key={i} quip={quip} text={text} size={size} />
          );
        }

        case 'HTML': {
          const { html } = properties.embed;
          return (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: html }} />
          );
        }

        case 'SUMMARY_BOX': {
          const { headline, items } = properties.embed;
          return (
            <SummaryBox
              key={i}
              items={items}
              headline={headline}
              vertical={vertical}
            />
          );
        }

        case 'FAQ': {
          const { faqs, headline } = properties.embed;
          return (
            <FAQEmbed id={i} faqs={faqs} headline={headline} />
          );
        }

        case 'SHOW_MORE_BUTTON': {
          const { displayShowMore } = properties.embed;

          return displayShowMore && (
            <ButtonShowMore
              size={size}
              title="Show More"
              onShowMoreClick={setShowHiddenMarkupAndEmbeds}
              key="show-more-button"
            />
          );
        }

        case 'ALL_PURPOSE_EMBED': {
          const advEmbItem = {
            ...item,
            widget: {
              ...(item?.widget ?? {}),
              properties: {
                ...(item?.widget?.properties ?? {}),
                'embed-code': properties?.embed?.code ?? '',
              },
            },
          };
          return <EmbedWidget {...advEmbItem} key={i} />;
        }
        case 'COMMERCE_QUICK_TAKES': {
          const {
            embed: {
              title,
              subtitle,
              products,
            },
          } = properties;

          return (
            <QuickTake
              title={title}
              subTitle={subtitle}
              products={products}
            />
          );
        }

        default:
          return getDebugComponent(item, i);
      }
    }

    default:
      return <EmbedWidget {...item} key={i} />;
  }
}

function getBodyItemComponent({
  item,
  isArticleEcommerceEnabled,
  i,
  path,
  articlePublishDate,
  vertical,
  lazyLoadSection = true,
  setShowHiddenMarkupAndEmbeds,
  articleId,
  articleTrackingId,
}) {
  switch (item.type) {
    case 'markup':
      return htmlElement(item, i, path);
    case 'embeddedCuratedList': {
      const { curatedList } = item;
      if (curatedList && articleId) {
        const {
          id,
          headline,
          body,
          ecommerceMetadata,
          ecommerceEnabled,
          content,
        } = curatedList;
        return (
          <CuratedList
            id={id}
            articleId={articleId}
            title={headline?.primary}
            description={body?.map((b) => b.html)?.join('')}
            curatedListTrackingId={ecommerceMetadata?.trackingId}
            articleTrackingId={articleTrackingId}
            ecommerceEnabled={ecommerceEnabled}
            disclaimerOverride={ecommerceMetadata?.disclaimer?.body[0]?.html}
            insertEndmark={item.insertEndmark}
            items={content?.items}
            path={path}
            shortDisclaimer={ecommerceMetadata?.shortDisclaimer}
          />
        );
      }
      return null;
    }
    case 'embeddedWidget':
      return getWidgetComponent(item, i, setShowHiddenMarkupAndEmbeds, isArticleEcommerceEnabled, vertical);

    case 'embeddedImage':
      return getImageComponent(item, i, lazyLoadSection);

    case 'embeddedVideo':
      if (!item.video) {
        return null;
      }
      return (
        <InlineVideo
          addClassNames={classNames({ endmark: item.insertEndmark })}
          disablePlaylistAutoplay={item.disablePlaylistAutoplay}
          video={item.video}
          presentation={item.presentation}
          key={item.video.id}
          elementId={item.elementId}
        />
      );

    case 'hr':
      return <hr key={i} />;

    case 'advertisement':
      return <Ad key={i} offsetViewport={100} slot="boxinline" />;

    case 'taboolaReadMoreBelow':
      return <div id="taboolaReadMoreBelow" key={i} />;

    case 'embeddedVideoPlaylist':
      return null;

    case 'embeddedRecipe':
      return <ContentLinkCard content={item.recipe} key={i} />;

    case 'embeddedSlideshow':
      return <ContentLinkCard content={item.slideshow} key={i} />;

    case 'shoppingCartCarousel':
      return <ShoppingCart article={item.article} vertical={item.vertical} key={i} />;

    case 'embeddedProduct': {
      const { product, presentation, elementId } = item || {};
      if (!product) {
        return null;
      }
      return (
        <LazyListicleProduct
          key={`product-${product.id}`}
          addClassNames={classNames({ endmark: item.insertEndmark })}
          product={product}
          size={presentation?.size ?? null}
          articlePublishDate={articlePublishDate}
          vertical={vertical}
          elementId={elementId}
        />
      );
    }

    case 'embeddedTaxonomy':
      // Hide taxonomy widget. Component rendered in articleBody
      return null;

    case 'externalLink':
      return (
        <p key={i}>
          <Link href={item.url}>Read more here</Link>
          .
        </p>
      );

    case 'inlineNewsletter':
      return (
        <RunExperiment
          key={`ABTestVWO-${i}`}
          campaignNumber={runExperimentNewsletterSignupInlineCampaignNumber[vertical]}
          variantComponents={[
            <NewsletterSignupInline
              {...item.props}
            />,
          ]}
        >
          <NewsletterSignupInline
            shouldThumbnailRender={false}
            {...item.props}
          />
        </RunExperiment>
      );

    case 'recommended': {
      return getRecommendationComponent(item, i, vertical);
    }

    case TABOOLA_RECO_REEL_TYPE: {
      return <TaboolaRecoReel key="taboola-reco-reel" />;
    }

    case 'embeddedContentModule':
      return (
        <ContentModule
          activityMapId="content_embed_viewed"
          htmlElement={htmlElement}
          icid="content_embed"
          item={item}
          path={path}
          vertical={vertical}
        />
      );

    default:
      return getDebugComponent(item, i);
  }
}

export { htmlElement, getBodyItemComponent, getWidgetComponent };
